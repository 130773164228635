/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"No borders"} description={"Experimental"}>
        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"ays2zuj2f4j"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Musical Moment No. 1"}>
              </Title>

              <Text className="text-box" content={"Špetka kombinování různých stylů pro klavír<br>Little peace of musical blending for piano solo"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" content={"Home"} use={"page"} href={"/"}>
              </Button>

              <YouTube embedId={"wKcG_hZxlfE"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"opc9j9kyv09"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Pulsar"}>
              </Title>

              <Text className="text-box" content={"Poleťte se mnou do vesmíru mikrointervalů<br>Welcome to the world of microintervals<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"SHp-xbjAr7c"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"t1oqgznhcbj"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Soutoky ticha"}>
              </Title>

              <Text className="text-box" content={"Soutoky ticha vznikly na HAMU, kde v sále Martinů byly natočeny <br>Třívětá skladba pro sólový klarinet<br>Confluences Of The Silence<br>were written during my studies at Academy Of Performing Arts in Prague<br>3 movements for clarinet solo<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"w7eWXl43mYQ"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"82sox6yoaq6"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Keyes Of Peace"}>
              </Title>

              <Text className="text-box" content={"Jazz pohrávající si s mikrointervaly a násobnými rytmy<br>Film-like jazz containing odd rhythms and microintervals"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=DOfPshvoPik&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=3"} href={"https://www.youtube.com/watch?v=DOfPshvoPik&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=3"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Dobrú noc"}>
              </Title>

              <Text className="text-box" content={"Volná improvizace na slovenskou lidovou píseň Dobrú noc<br>Free improvisation on Slovak folk song Dobrú noc"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=YGwiQXylzNQ&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=2"} href={"https://www.youtube.com/watch?v=YGwiQXylzNQ&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Vineyard"}>
              </Title>

              <Text className="text-box" content={"Experiment s vokály a latinsko americkými rytmy<br>Experiment with vocals and latino rythms"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=KiBlYz6R8_E&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=5"} href={"https://www.youtube.com/watch?v=KiBlYz6R8_E&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=5"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"fgcifvb33wb"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Zen Wind"}>
              </Title>

              <Text className="text-box" content={"Pop symphony"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=1oI9wM8Hr34&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=7"} href={"https://www.youtube.com/watch?v=1oI9wM8Hr34&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=7"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Pastime In Nature"}>
              </Title>

              <Text className="text-box" content={"String quartet and jazz organs"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=8ASS4by4IpA&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=4"} href={"https://www.youtube.com/watch?v=8ASS4by4IpA&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=4"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}